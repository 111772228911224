import React, { FC } from "react";

import { ITextProps } from "common/types";

import "./styles.scss";

export const H1: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = false,
}) =>
  isHtmlHeader ? (
    <h1 style={style} className={`h1 ${className}`}>
      {children}
    </h1>
  ) : (
    <h2 style={style} className={`h1 ${className}`}>
      {children}
    </h2>
  );

export const H2: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = true,
}) =>
  isHtmlHeader ? (
    <h2 style={style} className={`h2 ${className}`}>
      {children}
    </h2>
  ) : (
    <p style={style} className={`h2 ${className}`}>
      {children}
    </p>
  );

export const H3: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = true,
}) =>
  isHtmlHeader ? (
    <h3 style={style} className={`h3 ${className}`}>
      {children}
    </h3>
  ) : (
    <p style={style} className={`h3 ${className}`}>
      {children}
    </p>
  );

export const H4: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = true,
}) =>
  isHtmlHeader ? (
    <h4 style={style} className={`h4 ${className}`}>
      {children}
    </h4>
  ) : (
    <p style={style} className={`h4 ${className}`}>
      {children}
    </p>
  );

export const H5: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = true,
}) =>
  isHtmlHeader ? (
    <h5 style={style} className={`h5 ${className}`}>
      {children}
    </h5>
  ) : (
    <p style={style} className={`h5 ${className}`}>
      {children}
    </p>
  );

export const H6: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
  isHtmlHeader = true,
}) =>
  isHtmlHeader ? (
    <h6 style={style} className={`h6 ${className}`}>
      {children}
    </h6>
  ) : (
    <p style={style} className={`h6 ${className}`}>
      {children}
    </p>
  );

export const HP: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
}) => (
  <p style={style} className={`highlighted-paragrah ${className}`}>
    {children}
  </p>
);

export const UHP: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
}) => (
  <p style={style} className={`uppercase-highlighted-paragrah ${className}`}>
    {children}
  </p>
);

export const P: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
}) => (
  <p style={style} className={`paragrah ${className}`}>
    {children}
  </p>
);

export const BP: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
}) => (
  <p style={style} className={`bold-paragraph ${className}`}>
    {children}
  </p>
);

export const CP: FC<ITextProps> = ({
  className = "",
  style = {},
  children,
}) => (
  <p style={style} className={`cursive-paragraph ${className}`}>
    {children}
  </p>
)
